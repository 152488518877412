import styled from "styled-components";
import {animated} from "react-spring";
import {ISlideProps} from "../interface";

export const Link = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  transform-origin: left;
  transition: transform .3s linear;

  :hover {
    transition: transform .3s linear;
    transform-origin: left;
    transform: scale(1.1);
  }
`;
export const LinkText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;
export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow: hidden;
`;
export const ContainerMobile = styled(Container)`
  flex-direction: column;
  overflow: scroll;
`;
export const Slide = styled(animated.a)<ISlideProps>`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  width: ${({active}) => active ? '100%' : '6%'};
  max-width: 100%;
  min-width: 8%;
  padding: ${({active}) => active ? '3%' : '2%'};
  flex-direction: ${({active}) => active ? 'row' : 'column-reverse'};
  background: ${({bg}) => bg};
  transition: padding .4s linear, width .4s linear;
  overflow: hidden;
  gap: 100px;
  position: relative;

  svg {
    transition: all .4s linear;
    transform-origin: 60px 90px;
    transform: ${({active}) => active ? '' : 'rotate(-90deg) scale(60%)'};
  }
`;
export const Description = styled(animated.div)`
  display: flex;
  gap: 32px;
  flex-direction: column;
  transform-origin: bottom left;
`;

export const Info = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
`;

export const Subtitle = styled(animated.h3)`
  display: flex;
  width: 586px;
`;
// export const Image = styled(animated.div)<{src: string}>`
//   display: flex;
//   flex: 1 1 0;
// background: no-repeat url('${({src}) => src}');
//   background-size: cover;
// `;
export const Image = styled(animated.img)`

`;
export const ImageContainer = styled(animated.div)`
  display: flex;
  width: 50%;
  height: 90%;
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const SlideMobile = styled.div<{ bg: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  background: ${({bg}) => bg};

  ${Subtitle} {
    margin-top: 16px;
    width: fit-content;
  }

  ${Link} {
    margin-top: 24px;
    width: fit-content;
  }

  ${Image} {
    margin-top: 34px;
    max-width: 500px;
    min-width: 320px;
    height: auto;
  }
`;
