import React from "react";

export const ArrowRightBlack = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="11" width="16" height="2" fill="black"/>
    <path d="M15 6L21.5 12L15 18.5" stroke="black" strokeWidth="2"/>
</svg>

export const ArrowRightWhite = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="11" width="16" height="2" fill="white"/>
    <path d="M15 6L21.5 12L15 18.5" stroke="white" strokeWidth="2"/>
</svg>


export const DeckAuthLogoSmall = () => <svg width="192" height="40" viewBox="0 0 192 40" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0 39.1051V0.951233H9.2161C12.2881 0.951233 16.0222 1.06312 18.75 2.74144C22.0339 4.7834 22.5636 8.50368 22.5636 12.168V29.1191C22.5106 32.9233 21.3983 35.7484 18.9619 37.3708C16.7373 38.9093 14.036 39.161 11.4407 39.161H0V39.1051ZM8.47458 32.2519C14.1684 32.6715 14.2214 30.21 14.0625 25.1191V12.6435C14.0095 8.16802 12.6059 7.91627 8.47458 7.80438V32.2519Z"
        fill="white"/>
    <path
        d="M25.2133 39.1051V0.951233H44.6254V8.5876H33.6879V15.6086H44.2811V23.2449H33.6879V31.4967H45.208V39.1051H25.2133Z"
        fill="white"/>
    <path
        d="M61.8122 14.2937V11.8601C61.8122 8.61538 60.6999 6.93706 59.1109 6.93706C56.4626 6.88112 55.827 8.41958 55.827 10.9091L55.774 29.7902C55.774 31.9161 56.595 33.2028 58.8196 33.2028C61.7062 33.2028 61.9446 30.042 61.9446 27.7203V24.1958H70.4191V28.8671C70.4191 35.4406 66.8439 40 58.184 40C50.3715 40 47.3524 35.6084 47.3524 27.8322V12.1678C47.3524 3.86014 50.9806 0 58.7136 0C65.9435 0 70.2867 3.24476 70.2867 11.049V14.2937H61.8122Z"
        fill="white"/>
    <path
        d="M72.8031 39.1051V0.951233H81.3041V16.224H81.3836L87.6601 0.951233H96.32L88.6134 17.7904L96.8232 39.1051H87.8454L83.1844 25.8463L81.3041 29.7065V39.1051H72.8031Z"
        fill="white"/>
    <path
        d="M105.719 0.951721H116.392L122.959 39.1056H114.511L113.77 32.3084H108.129L107.308 39.1056H99.2571L105.719 0.951721ZM109.294 25.5671H113.452L111.519 6.88179H111.439L109.294 25.5671Z"
        fill="#59B1FF"/>
    <path
        d="M125.344 0.951721H133.66V28.8398C133.634 29.8468 133.739 30.7419 133.978 31.4972C134.269 32.3084 134.958 32.7 136.07 32.7C136.706 32.7 137.182 32.5601 137.527 32.3084C137.844 32.1126 138.083 31.8608 138.215 31.4972C138.427 30.7419 138.506 29.8468 138.48 28.8398V0.951721H146.769V28.7C146.61 35.8888 143.035 39.5531 136.044 39.721C129.026 39.5531 125.45 35.8888 125.344 28.7V0.951721Z"
        fill="#59B1FF"/>
    <path d="M149.602 0.951721H168.326V8.28039H163.108V39.1335H154.819V8.28039H149.602V0.951721Z" fill="#59B1FF"/>
    <path
        d="M170.575 0.951721H178.891V15.2175H183.711V0.951721H192V39.1056H183.711V22.8818H178.891V39.1056H170.575V0.951721Z"
        fill="#59B1FF"/>
</svg>

export const DeckAirLogoSmall = () => <svg width="156" height="40" viewBox="0 0 156 40" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_500_2848)">
        <path
            d="M0 39.0735L0 0.918427H9.08912C12.1339 0.918427 15.7967 1.02316 18.5098 2.69889C21.7506 4.7291 22.2706 8.45115 22.2706 12.1088V29.0514C22.2254 32.8701 21.125 35.6657 18.6983 37.293C16.5127 38.8157 13.8447 39.0735 11.2747 39.0735H0ZM8.38068 32.2095C13.9954 32.6123 14.0482 30.1792 13.8975 25.0796V12.6163C13.8522 8.13696 12.473 7.88721 8.38068 7.78247V32.2095Z"
            fill="black"/>
        <path
            d="M24.8933 39.0735V0.918427H44.0739V8.54783H33.2665V15.565H43.7423V23.1944H33.2665V31.4441H44.6392V39.0735H24.8933Z"
            fill="black"/>
        <path
            d="M61.0614 14.2518V11.8107C61.0614 8.55589 59.9686 6.88016 58.3934 6.88016C55.7783 6.83182 55.1527 8.35448 55.1527 10.852L55.1075 29.7281C55.1075 31.863 55.9214 33.1359 58.1071 33.1359C60.9634 33.1359 61.1971 29.9778 61.1971 27.6495V24.1853H69.5777V28.8661C69.5777 35.424 66.0582 40.0081 57.4815 40.0081C49.7716 40.0081 46.772 35.6334 46.772 27.851V12.1652C46.7796 3.86707 50.3519 0 58.0166 0C65.1613 0 69.4421 3.25478 69.4421 11.0373V14.2437L61.0614 14.2518Z"
            fill="black"/>
        <path
            d="M71.9141 39.0735V0.918427H80.2948V16.1772H80.3927L86.5727 0.918427H95.1418L87.5299 17.7643L95.6166 39.0735H86.7687L82.1488 25.7966L80.2872 29.6636V39.0735H71.9141Z"
            fill="black"/>
        <path
            d="M103.334 0.926422H113.885L120.367 39.0815H112.031L111.293 32.298H105.723L104.902 39.0815H96.9431L103.334 0.926422ZM106.861 25.5306H110.961L109.039 6.84787H108.949L106.861 25.5306Z"
            fill="white"/>
        <path d="M122.711 0.926483H130.91V39.0735H122.711V0.926483Z" fill="white"/>
        <path
            d="M133.789 0.926477H144.642C151.659 0.725067 155.126 3.79455 155.043 10.1349C155.073 12.3585 154.801 14.2115 154.229 15.6858C153.573 17.144 152.186 18.2397 150.061 18.9728V19.0695C153.158 19.8026 154.756 21.8248 154.862 25.1359V31.8791C154.862 33.1843 154.892 34.5378 154.945 35.9396C155.028 37.3414 155.269 38.3887 155.668 39.0896H147.589C147.167 38.3887 146.903 37.3414 146.783 35.9396C146.669 34.5378 146.624 33.1843 146.654 31.8791V25.7643C146.654 24.6445 146.428 23.8308 145.976 23.3152C145.494 22.8318 144.665 22.5901 143.497 22.5901H141.982V39.0735H133.782V0.926477H133.789ZM141.997 16.1047H144.017C145.396 16.1047 146.232 15.6616 146.541 14.7754C146.775 13.9376 146.881 12.8741 146.85 11.6012C146.85 10.9325 146.835 10.3363 146.805 9.80462C146.775 9.25679 146.662 8.82174 146.451 8.49949C146.263 8.12889 145.938 7.84692 145.486 7.66162C145.049 7.49244 144.416 7.40382 143.602 7.40382H142.004V16.1047H141.997Z"
            fill="white"/>
    </g>
    <defs>
        <clipPath id="clip0_500_2848">
            <rect width="155.676" height="40" fill="white"/>
        </clipPath>
    </defs>
</svg>

export const DeckLogoSmall = () => <svg width="96" height="40" viewBox="0 0 96 40" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0 39.0656L0 0.918243H9.08729C12.1315 0.918243 15.7935 1.02296 18.5061 2.69835C21.7462 4.72815 22.2661 8.44945 22.2661 12.1063V29.0455C22.2209 32.8635 21.1208 35.6585 18.6945 37.2855C16.5093 38.8079 13.8419 39.0656 11.2725 39.0656H0ZM8.37899 32.203C13.9926 32.6057 14.0454 30.1732 13.8947 25.0745V12.6138C13.8495 8.13532 12.4705 7.88562 8.37899 7.78091V32.203Z"
        fill="black"/>
    <path
        d="M24.8885 39.0656V0.918243H44.0653V8.54611H33.26V15.5618H43.7337V23.1897H33.26V31.4378H44.6304V39.0656H24.8885Z"
        fill="black"/>
    <path
        d="M61.0492 14.2489V11.8083C61.0492 8.55417 59.9567 6.87878 58.3818 6.87878C55.7672 6.83045 55.1418 8.3528 55.1418 10.8498L55.0965 29.7221C55.0965 31.8566 55.9103 33.1293 58.0955 33.1293C60.9513 33.1293 61.1849 29.9718 61.1849 27.644V24.1804H69.5639V28.8603C69.5639 35.4168 66.045 40 57.4701 40C49.7617 40 46.7628 35.6263 46.7628 27.8453V12.1627C46.7703 3.86629 50.3419 0 58.0051 0C65.1483 0 69.4282 3.25413 69.4282 11.035V14.2408L61.0492 14.2489Z"
        fill="black"/>
    <path
        d="M71.8994 39.0656V0.918243H80.2784V16.174H80.3764L86.5551 0.918243H95.1225L87.5121 17.7608L95.5972 39.0656H86.751L82.132 25.7914L80.2709 29.6577V39.0656H71.8994Z"
        fill="black"/>
</svg>

export const DeckLogo = () => <svg width="299" height="125" viewBox="0 0 299 125" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0 122.08L0 2.86938H28.3978C37.9108 2.86938 49.3547 3.19661 57.8316 8.43221C67.9569 14.7753 69.5816 26.4044 69.5816 37.8321V90.7671C69.4403 102.698 66.0025 111.433 58.4203 116.517C51.5917 121.275 43.256 122.08 35.2264 122.08H0ZM26.1844 100.634C43.7269 101.893 43.8918 94.291 43.4208 78.3577V39.4179C43.2795 25.4227 38.9704 24.6424 26.1844 24.3152V100.634Z"
        fill="black"/>
    <path
        d="M77.7756 122.08V2.86938H137.703V26.7065H103.936V48.6306H136.667V72.4677H103.936V98.2429H139.469V122.08H77.7756Z"
        fill="black"/>
    <path
        d="M190.78 44.5278V36.9009C190.78 26.7318 187.365 21.4962 182.444 21.4962C174.273 21.3451 172.319 26.1025 172.319 33.9056L172.177 92.8816C172.177 99.552 174.72 103.529 181.549 103.529C190.473 103.529 191.203 93.6619 191.203 86.3874V75.5638H217.388V90.1883C217.388 110.678 206.391 125 179.595 125C155.506 125 146.134 111.332 146.134 87.0167V38.0085C146.158 12.0822 157.319 0 181.267 0C203.589 0 216.964 10.1691 216.964 34.4845V44.5026L190.78 44.5278Z"
        fill="black"/>
    <path
        d="M224.685 122.08V2.86938H250.87V50.5436H251.176L270.484 2.86938H297.258L273.475 55.5023L298.741 122.08H271.097L256.662 80.5979L250.846 92.6801V122.08H224.685Z"
        fill="black"/>
</svg>


export const DeckAirLogo = () => <svg width="486" height="125" viewBox="0 0 486 125" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_642_3055)">
        <path
            d="M0 122.105L0 2.87012H28.3751C37.8805 2.87012 49.3153 3.19741 57.7854 8.43407C67.9026 14.7785 69.526 26.4099 69.526 37.8399V90.7855C69.3849 102.719 65.9497 111.455 58.3736 116.541C51.5504 121.299 43.2214 122.105 35.1983 122.105H0ZM26.1634 100.655C43.692 101.913 43.8567 94.3102 43.3861 78.3736V39.426C43.245 25.428 38.9393 24.6476 26.1634 24.3203V100.655Z"
            fill="black"/>
        <path
            d="M77.7139 122.105V2.87012H137.593V26.712H103.854V48.6405H136.558V72.4824H103.854V98.2629H139.358V122.105H77.7139Z"
            fill="black"/>
        <path
            d="M190.626 44.5368V36.9084C190.626 26.7372 187.214 21.5005 182.297 21.5005C174.133 21.3494 172.18 26.1078 172.18 33.9124L172.039 92.9003C172.039 99.572 174.58 103.55 181.403 103.55C190.32 103.55 191.05 93.6808 191.05 86.4048V75.579H217.213V90.2064C217.213 110.7 206.225 125.025 179.45 125.025C155.381 125.025 146.016 111.354 146.016 87.0342V38.0161C146.04 12.0846 157.192 0 181.121 0C203.425 0 216.79 10.1712 216.79 34.4914V44.5116L190.626 44.5368Z"
            fill="black"/>
        <path
            d="M224.507 122.105V2.87012H250.67V50.5539H250.976L270.269 2.87012H297.021L273.257 55.5136L298.503 122.105H270.881L256.458 80.6143L250.647 92.6989V122.105H224.507Z"
            fill="black"/>
        <path
            d="M322.596 2.89502H355.536L375.77 122.13H349.748L347.442 100.931H330.055L327.49 122.13H302.644L322.596 2.89502ZM333.607 79.7832H346.407L340.407 21.3995H340.125L333.607 79.7832Z"
            fill="white"/>
        <path d="M383.087 2.89526H408.686V122.105H383.087V2.89526Z" fill="white"/>
        <path
            d="M417.674 2.89528H451.555C473.459 2.26587 484.282 11.858 484.024 31.6717C484.118 38.6204 483.271 44.4109 481.483 49.0181C479.436 53.575 475.106 56.999 468.471 59.29V59.5922C478.142 61.8832 483.13 68.2024 483.459 78.5499V99.6224C483.459 103.701 483.553 107.931 483.718 112.311C483.977 116.692 484.729 119.965 485.976 122.155H460.754C459.437 119.965 458.613 116.692 458.237 112.311C457.884 107.931 457.743 103.701 457.837 99.6224V80.5136C457.837 77.0141 457.131 74.4713 455.719 72.86C454.213 71.3495 451.625 70.5942 447.978 70.5942H443.249V122.105H417.65V2.89528H417.674ZM443.296 50.3273H449.602C453.907 50.3273 456.519 48.9426 457.484 46.1732C458.213 43.5549 458.543 40.2316 458.448 36.2538C458.448 34.1642 458.401 32.3011 458.307 30.6395C458.213 28.9275 457.86 27.568 457.201 26.5609C456.613 25.4028 455.601 24.5217 454.19 23.9426C452.825 23.4139 450.849 23.137 448.308 23.137H443.32V50.3273H443.296Z"
            fill="white"/>
    </g>
    <defs>
        <clipPath id="clip0_642_3055">
            <rect width="486" height="125" fill="white"/>
        </clipPath>
    </defs>
</svg>


export const DeckAuthLogo = () => <svg width="594" height="126" viewBox="0 0 594 126" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0.320435 122.572V3.27368H28.6345C38.0725 3.27368 49.5446 3.62353 57.9249 8.87124C68.0139 15.256 69.6411 26.8884 69.6411 38.3459V91.3478C69.4784 103.243 66.0612 112.076 58.5758 117.149C51.7414 121.959 43.4425 122.747 35.4689 122.747H0.320435V122.572ZM26.3564 101.143C43.8493 102.455 44.012 94.7588 43.5238 78.8407V39.8327C43.3611 25.8388 39.0489 25.0517 26.3564 24.7018V101.143Z"
        fill="white"/>
    <path
        d="M77.7816 122.572V3.27368H137.42V27.1508H103.818V49.1037H136.362V72.9808H103.818V98.782H139.21V122.572H77.7816Z"
        fill="white"/>
    <path
        d="M190.222 44.9921V37.3829C190.222 27.2373 186.805 21.9896 181.923 21.9896C173.787 21.8147 171.834 26.6251 171.834 34.4092L171.672 93.4459C171.672 100.093 174.194 104.116 181.028 104.116C189.897 104.116 190.629 94.2331 190.629 86.9737V75.9536H216.665V90.5597C216.665 111.113 205.681 125.369 179.076 125.369C155.074 125.369 145.798 111.638 145.798 87.3236V38.345C145.798 12.3688 156.945 0.299072 180.703 0.299072C202.915 0.299072 216.258 10.4446 216.258 34.8465V44.9921H190.222Z"
        fill="white"/>
    <path
        d="M223.99 122.572V3.27368H250.107V51.0278H250.351L269.634 3.27368H296.239L272.563 55.9257L297.785 122.572H270.203L255.884 81.1147L250.107 93.1845V122.572H223.99Z"
        fill="white"/>
    <path
        d="M322.501 3.27417H355.476L375.769 122.572H349.667L347.376 101.319H329.947L327.41 122.572H302.535L322.501 3.27417ZM333.547 80.2406H346.394L340.42 21.8161H340.175L333.547 80.2406Z"
        fill="#59B1FF"/>
    <path
        d="M384.201 3.27417H410.233V90.4736C410.15 93.6222 410.482 96.421 411.228 98.7825C412.14 101.319 414.295 102.543 417.777 102.543C419.767 102.543 421.259 102.106 422.337 101.319C423.332 100.707 424.078 99.9195 424.493 98.7825C425.156 96.421 425.405 93.6222 425.322 90.4736V3.27417H451.271V90.0363C450.773 112.514 439.581 123.971 417.694 124.496C395.725 123.971 384.533 112.514 384.201 90.0363V3.27417V3.27417Z"
        fill="#59B1FF"/>
    <path d="M460.139 3.27417H518.752V26.1892H502.42V122.66H476.471V26.1892H460.139V3.27417Z" fill="#59B1FF"/>
    <path
        d="M525.794 3.27417H551.826V47.8797H566.915V3.27417H592.864V122.572H566.915V71.8442H551.826V122.572H525.794V3.27417Z"
        fill="#59B1FF"/>
</svg>


export const BlackArrow = () => <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 18L0 0L88 0V88H70V30.6812L13 87.6812L0.272095 74.9533L57.2254 18L0 18Z" fill="black"/>
</svg>

export const WhiteArrow = () => <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 18L0 0L88 0V88H70V30.6812L13 87.6812L0.272095 74.9533L57.2254 18L0 18Z" fill="white"/>
</svg>